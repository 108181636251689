







import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";

export default Vue.extend({
  props: ["item", "retailerId"],
  components: {},
  data() {
    const self = this;
    return {
      self,
      tableContainer: new DataContainer(),
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
          "hide-default-footer": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            name: {
              text: "Site",
              options: {}
            },
            address: {
              text: "Address",
              options: {
                subProp: "address",
                tooltip: {
                  content: {
                    text: "address",
                  },
                },
                attrs: {
                  class: "address-sites two-line-text",
                },
              },
            },
            description: {
              text: "Description",
              options: {
                subProp: "description",
                tooltip: {
                  content: {
                    text: "description",
                  },
                },
                attrs: {
                  class: "description-sites two-line-text",
                },
              },
            },
            createdTime: {
              text: "Created Time",
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              options: {
                filter: "dateTime",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll() {
              const filters = [
                {
                  key: "organizationSiteId",
                  operator: "equal_to",
                  value: self.item.id,
                },
              ];
              const {items, count} = await coreApiClient.call(
                "chargingLocations",
                "findAll",
                {
                  limit: 1000,
                  skip: 0,
                  sort: {
                    name: 1,
                  },
                  payload: JSON.stringify({
                    limit: 1000,
                    filters,
                  }),
                }
              );
              return [items, count];
            },
          },
        },
      },
    };
  },
  methods: {},
  created() {
  },
  destroyed() {
  },
});
