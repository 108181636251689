

















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import SiteInfo from "@/views/app/site_area/SiteInfo.vue";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: {
    BlurredTenant, SiteInfo
  },
  data() {
    const self = this;
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      self,
      userManager,
      position: null,
      addPosition: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
          "show-expand": true,
        },
        content: {
          name: "Site Area",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              name: {
                attrs: {
                  label: "Site Area",
                },
                rules: {
                  match: {},
                },
              },
            },
          },
          displayFields: {
            name: {
              text: "Site Area",
              sortable: true,
              options: {}
            },
            address: {
              text: "Address",
              sortable: true,
              options: {
                subProp: "address",
                tooltip: {
                  content: {
                    text: "address",
                  },
                },
                attrs: {
                  class: "address-sites-area two-line-text",
                },
              },
            },
            description: {
              text: "Description",
              sortable: true,
              options: {
                subProp: "description",
                tooltip: {
                  content: {
                    text: "description",
                  },
                },
                attrs: {
                  class: "description-site-area two-line-text",
                },
              },
            },
            action: {
              text: "Actions",
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              ext: {
                condition() {
                  return appService.activeTenantValid();
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const payload = JSON.parse(options.payload) || {};
              const filters = payload.filters || [];
              if (userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], true, true)) {
                filters.push({
                  key: "tenantId",
                  operator: "equal_to",
                  value: userManager.getTenantId(),
                });
              }
              options.payload = JSON.stringify(payload);

              const { items, count } = await coreApiClient.call("organizationSites", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("organizationSites", "create", undefined, item);
            },
            async update(item) {
              const payload = {
                "name": item.name || '',
                "address": item.address || '',
                "description": item.description || '',
                "tenantId": item.tenantId
              }
              return await coreApiClient.call(
                "organizationSites",
                "update",
                {
                  id: item._id,
                },
                {
                  data: payload,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("organizationSites", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Name",
                  required: true,
                },
              },
              address: {
                type: "textLarge",
                attrs: {
                  label: "Address",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "address", "description"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "address", "description"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
  methods: {},
  async created() {
  },
});
